<div class="ui fixed pointing menu">
    <a class="toc item">
        <div class="ui grid">
            <div class="logo widescreen large screen computer only row">
                <div class="logo wide column">
                    <img class="company-logo" src="assets/img/company-logo.png" />
                </div>
            </div>
            <div class="logo tablet mobile only row">
                <div class="logo wide column">
                    <img class="small-company-logo" src="assets/img/small-company-logo.png" />
                </div>
            </div>
        </div>
    </a>
    <a class="item" routerLink="/home" [routerLinkActive]="['active']">
        <i class="desktop icon"></i>
        Home
    </a>
    <a class="item" routerLink="/register" [routerLinkActive]="['active']">
        <i class="lightbulb outline icon"></i>
        Register
    </a>
    <a class="item" routerLink="/support" [routerLinkActive]="['active']">
        <i class="user circle icon"></i>
        Support
    </a>
    <a href="http://app.yourworkforce.com.au?hash={{ hash }}" class="right item">
        <i class="user outline icon"></i>
        Sign in
    </a>
</div>
<router-outlet>
</router-outlet>
<div class="ui inverted vertical footer segment">
    <div class="ui container">
        <div class="ui stackable inverted divided equal height stackable grid">
            <div class="three wide widescreen three wide computer four tablet six wide mobile column">
                <h4 class="ui inverted header">Information</h4>
                <div class="ui inverted link list">
                    <a href="mailto:support@yourworkforce.com.au" class="item">
                        Contact Us
                    </a>
                </div>
            </div>
            <div class="three wide widescreen three wide computer four tablet six wide mobile column">
                <h4 class="ui inverted header">Features</h4>
                <div class="ui inverted link list">
                    <a class="item">Price Management</a>
                    <a class="item">Contact Management</a>
                    <a class="item">Communication</a>
                    <a class="item">Invoicing</a>
                </div>
            </div>
            <div class="three wide widescreen three wide computer four tablet six wide mobile column">
                <h4 class="ui inverted header">Integrations</h4>
                <div class="ui inverted link list">
                    <a class="item">Xero</a>
                </div>
            </div>
            <div class="four wide widescreen four wide computer twelve tablet six wide mobile column">
                <h4 class="ui inverted header">About</h4>
                <p>Access and manage all your jobs from anywhere using our platform.</p>
            </div>
        </div>
    </div>
</div>