import { Injectable } from '@angular/core';
import { Testimonial } from '../interfaces/tertimonial.interface';

@Injectable()
export class TestimonialStore {

  public collection: Testimonial[];

  constructor() {
    this.initialize();
  }

  private initialize(): void {
    this.collection = [
      {
        photo: 'person-1.png',
        content: 'We have been using Your Work Force in private beta for a couple of months now. It is incredibly user friendly and efficient at scheduling and keeping track of our crews and equipment. Being able to clone appointments and bulk change status has saved our team massive amounts of time meaning they can concentrate on delivering excellent service.',
        author: 'Peter Emmett',
        starts: 5
      },
      {
        photo: 'person-2.png',
        content: 'Being cloud based we can access the application from anywhere, I can have our sales team adding customer requirements in the field. It’s easy to use and the daily dashboard gives a great high-level overview of what needs attending. Great product.',
        author: 'Alan Cassar',
        starts: 5
      },
      {
        photo: 'person-3.png',
        content: 'Integrates well with Xero. I love the fact that I can choose to collate all appointment transactions to pending invoices and then invoice daily, weekly, monthly in a format that caters to our customers individual requirements. This build customer loyalty. They are always adding new features.',
        author: 'Alana Haslett',
        starts: 5
      }
    ];
  }
}
