import { BehaviorSubject, interval, timer } from 'rxjs';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { Testimonial } from 'src/app/interfaces/tertimonial.interface';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-testimonial-carousel',
  templateUrl: './testimonial-carousel.component.html',
  styleUrls: ['./testimonial-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('trigger', [
      transition('* => *', [
        animate('1s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ])
    ])
  ]
})
export class TestimonialCarouselComponent {

  public current$ = new BehaviorSubject(undefined);

  private set current(nextState: Testimonial) {
    this.current$.next(nextState);
  }

  constructor(public store: TestimonialStore) {
    this.initialize();
  }

  private initialize(): void {
    timer(0, 5000).subscribe(round => {
      const selection = round % this.store.collection.length;
      this.current = this.store.collection[selection];
    });
  }
}
