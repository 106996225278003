<div class="content presentation">
</div>
<br />
<br />
<br />
<div class="ui centered grid grid">
  <div class="row">
    <div class="five wide widescreen eight wide computer twelve wide tablet fourteen wide mobile column">
      <h2 class="ui header">
        Support
        <div class="sub header">
          Please fill out the form below to send us your feedback or report an issue.
        </div>
      </h2>
    </div>
  </div>
  <div class="row">

  </div>
  <div class="row">
    <div class="five wide widescreen eight wide computer twelve wide tablet fourteen wide mobile column">
      <div class="ui form">
        <div class="required field">
          <label>Name</label>
          <input type="text" [(ngModel)]="entity.name" name="name" placeholder="Name">
          <div *ngIf="" class="ui basic red pointing prompt label transition visible">
            Please enter your name
          </div>
        </div>
        <div class="field">
          <label>Company Name</label>
          <input type="text" [(ngModel)]="entity.company" name="company-name" placeholder="Company Name">
        </div>
        <div class="required field">
          <label>Email</label>
          <input type="email" [(ngModel)]="entity.email" name="email" placeholder="Email">
        </div>
        <div class="field">
          <label>Description of your feedback or issue</label>
          <textarea rows="5" [(ngModel)]="entity.description" name="description">
          </textarea>
        </div>
        <button class="ui primary button" type="submit" (click)="send()">
          Send
        </button>
      </div>
    </div>
  </div>
</div>
<br />
<br />