<div class="content">
  <div class="ui three column centered grid">
    <div class="left aligned row">
      <div class="fifteen wide column">
        <h1>
          Testimonials
        </h1>
      </div>
    </div>
    <div *ngIf="current$ | async as testimonial" class="centered row">
      <div [@trigger]="testimonial.author" class="center aligned ten wide computer twelve wide tablet fourteen wide mobile center aligned column">
        <div class="ui massive star rating">
          <i class="icon active"></i>
          <i class="icon active"></i>
          <i class="icon active"></i>
          <i class="icon active"></i>
          <i class="icon active"></i>
        </div>
        <br />
        <h3>
          "{{ testimonial.content }}"
        </h3>
        <div class="ui divider"></div>
        <img class="ui avatar tiny image" [src]="'assets/img/' + testimonial.photo">
        <h5>
          {{ testimonial.author }}
        </h5>
      </div>
    </div>
  </div>
</div>