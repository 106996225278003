import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RegisterService } from 'src/app/services/register.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent {

  public entity: any = {
    name: undefined,
    company: undefined,
    description: undefined,
    email: undefined,
  };

  constructor(private service: RegisterService) {

  }

  public send(): void {
    this.service.support(this.entity).subscribe(response => {
      console.log('TCL: RegisterComponent -> response', response);
      this.entity = {};
    });
  }
}
