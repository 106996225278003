<div class="content presentation">
</div>
<br />
<br />
<br />

<div class="ui grid">
  <div class="row">
    <div class="center aligned column">
      <h2 class="ui header">
        Your Work Force is the premier cloud solution for scheduling and <br />
        managing your field personnel and equipment.
        <div class="sub header">
          <br />
          Deliver best in class customer service and transform your business!
        </div>
      </h2>
    </div>
  </div>
</div>
<br />
<br />
<br />

<div class="ui twelve column centered grid">
  <div class="centered row">
    <div class="center aligned three wide computer three wide tablet six wide mobile column">
      <i class="bordered even settings huge icon"></i>
      <h4>
        Job Management
        <br />
        <br />
      </h4>
    </div>
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered odd dollar sign huge icon"></i>
      <h4>
        Pricing Management
        <br />
        <br />
      </h4>
    </div>
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered even history huge icon"></i>
      <h4>
        Job History
      </h4>
    </div>
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered odd paper plane outline huge icon"></i>
      <h4>
        Easy Invoicing
      </h4>
    </div>
  </div>
  <div class="row">
  </div>
  <div class="centered row">
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered odd database huge icon"></i>
      <h4>
        Asset Database
        <br />
        <br />
      </h4>
    </div>
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered even search huge icon"></i>
      <h4>
        Advanced search capabilities
        <br />
        <br />
      </h4>
    </div>
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered odd handshake outline huge icon"></i>
      <h4>
        Xero Integration
      </h4>
    </div>
    <div class="center aligned three wide computer three wide tablet six wide mobile center aligned column">
      <i class="bordered even users huge icon"></i>
      <h4>
        Customer Portal
      </h4>
    </div>
  </div>
</div>
<br />
<br />
<br />

<app-testimonial-carousel>
</app-testimonial-carousel>
<br />
<br />
<br />

<div class="ui two column centered grid">
  <div class="column"></div>
  <div class="four column centered row">
    <div class="four wide widescreen five wide computer three eight tablet fifteen wide mobile column">
      <h2 class="ui header">
        <div class="content">
          Register Your Interest
          <div class="sub header">
            Our product is currently in private beta. This means it is not available for purchase just yet.
            If you are interested in participating in the beta, please click <a routerLink="/register">here</a>.
          </div>
        </div>
        <br />
        <br />
      </h2>
    </div>
    <div class="four wide widescreen five wide computer three eight tablet fifteen wide mobile column">
      <h2 class="ui header">
        <div class="content">
          Contact
          <div class="sub header">
            Level 1 / 420 High Street Kew Vic 3101<br />
            <i class="phone small icon"></i><a href="tel:1300 562 387">1300 562 387</a><br />
            <i class="envelope outline small icon"></i><a href="mailto:info@yourworkforce.com.au">info@yourworkforce.com.au</a>
          </div>
        </div>
      </h2>
    </div>
  </div>
</div>
<br />
<br />
<br />