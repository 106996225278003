import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RegisterComponent } from './components/register/register.component';
import { RegisterService } from './services/register.service';
import { RouterModule } from '@angular/router';
import { TestimonialCarouselComponent } from './components/testimonial-carousel/testimonial-carousel.component';
import { TestimonialStore } from 'src/app/stores/testimonial.store';
import { SupportComponent } from './components/support/support.component';

@NgModule({
  declarations:
    [
      AppComponent,
      HomeComponent,
      RegisterComponent,
      SupportComponent,
      TestimonialCarouselComponent
    ],
  imports:
    [
      FormsModule,
      CommonModule,
      RouterModule,
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      BrowserAnimationsModule
    ],
  providers:
    [
      TestimonialStore,
      RegisterService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
