import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Register } from '../interfaces/register.interface';

const service = `https://prod-26.australiasoutheast.logic.azure.com:443/workflows/b3feaa9ee4a345118dc01d9b40714f8a/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=Ww_GekUu9g5ZXQsti1M1Rqm3a-89_8Fshfp0H0sCuJM`;

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient) {

  }

  public register(request: any): Observable<Register> {
    request.support = false;
    request.registration = true;
    return this.http.post(service, request) as Observable<any>;
  }

  public support(request: any): Observable<Register> {
    request.support = true;
    request.registration = false;
    return this.http.post(service, request) as Observable<any>;
  }
}
