import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Register } from 'src/app/interfaces/register.interface';
import { RegisterService } from 'src/app/services/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent {

  public entity: any = {
    name: undefined,
    company: undefined,
    description: undefined,
    email: undefined,
    phoneNumber: undefined
  };

  constructor(private service: RegisterService) {

  }

  public send(): void {
    this.service.register(this.entity).subscribe(response => {
      console.log('TCL: RegisterComponent -> response', response);
      this.entity = {};
    });
  }
}
